<template>
    <div style="position: absolute;width: 100vw; height: 100vh;">

      <!-- <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="2">
          <b v-text="editType === 'add' ? '新增博客' : (editType === 'draft' ? '编辑草稿' : '编辑博客')"></b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="10">
          <div style="display: flex;justify-content: flex-end;">
            <vs-button
              gradient
            >
              <i class="bx bxs-file-import"></i> Import
            </vs-button>
            <vs-button
              gradient
            >
              <i class="bx bxs-file-export"></i> Export
            </vs-button>
            <vs-button
              gradient
              @click="showDraftDialog"
            >
              <i class="bx bxs-purchase-tag"></i> Draft List
            </vs-button>
            <vs-button
              gradient
              @click="save"
            >
              <i class="bx bxs-paper-plane"></i> Save
            </vs-button>
            <vs-button
              gradient
              @click="publish"
            >
              <i class="bx bxs-paper-plane"></i> Publish
            </vs-button>
          </div>
        </vs-col>
      </vs-row> -->

      <!-- md编辑组件 -->
      <MdEditor :content="content" ref="mdEditor" @getNewHtml="getNewHtml"></MdEditor>

      <!-- 草稿列表 -->
      <vs-dialog overflow-hidden full-screen v-model="draftDialogFlag">
        <vs-table
          v-model="selectedDraftList"
          style="height: 100%; width: 100%;"
          >
          <template #header>
            <vs-input v-model="search" border placeholder="Search"/>
            <vs-button @click="deleteselectedDraftList(null)" danger> Batch Delete </vs-button>
          </template>
          <template #thead>
            <vs-tr>
              <vs-th style="width: 80px;">
                <vs-checkbox
                  :indeterminate="selectedDraftList.length == draftList.length" v-model="allCheck"
                  @change="selectedDraftList = $vs.checkAll(selectedDraftList, draftList)"
                />
              </vs-th>
              <vs-th sort @click="draftList = $vs.sortData($event ,draftList, 'title')">
                Title
              </vs-th>
              <vs-th sort @click="draftList = $vs.sortData($event ,draftList, 'description')">
                Description
              </vs-th>
              <vs-th sort @click="draftList = $vs.sortData($event ,draftList, 'content')">
                Status
              </vs-th>
              <vs-th sort @click="draftList = $vs.sortData($event ,draftList, 'record_time')">
                Time
              </vs-th>
              <vs-th>
                Operation
              </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr
              :key="i"
              v-for="(tr, i) in $vs.getPage($vs.getSearch(draftList, search), page, max)"
              :data="tr"
              :is-selected="!!selectedDraftList.includes(tr)"
              open-expand-only-td
            >
              <vs-td checkbox>
                <vs-checkbox :val="tr" v-model="selectedDraftList" />
              </vs-td>
              <vs-td>
                {{ tr.title }}
              </vs-td>
              <vs-td>
                {{ tr.description }}
              </vs-td>
              <vs-td>
                <vs-button disabled success v-if="tr.status === '0'">PUBLIC</vs-button>
                <vs-button disabled danger v-else>PRIVATE</vs-button>
              </vs-td>
              <vs-td>
                {{ tr.record_time }}
              </vs-td>
              <vs-td style="display: flex; justify-content: flex-start;">
                <vs-button @click="deleteselectedDraftList(tr.id)" danger> Delete </vs-button>
                <vs-button default> Edit </vs-button>
                <vs-button @click="test(tr.id)" success> Publish </vs-button>
              </vs-td>
            </vs-tr>
          </template>
          <template #footer>
            <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(draftList, search), max)" />
          </template>
        </vs-table>
      </vs-dialog>

      <!-- 确认框 -->
      <vs-dialog width="550px" not-center v-model="confirmDialogFlag">
        <template #header>
          <h4 class="not-margin">
            <b>Confirm {{ modifyType }}</b>
          </h4>
        </template>

        <div class="con-content">
          <!-- id -->
          <vs-input color="#7d33ff" type="text" v-model="data.id" placeholder="id" :disabled="disabled">
            <template #icon>
              <i class='bx bx-lock-open-alt'></i>
            </template>
          </vs-input>
          <!-- title -->
          <vs-input color="#7d33ff" type="text" v-model="data.title" placeholder="title">
            <template #icon>
              <i class='bx bx-lock-open-alt'></i>
            </template>
          </vs-input>
          <!-- description -->
          <vs-input color="#7d33ff" type="text" v-model="data.description" placeholder="description">
            <template #icon>
              <i class='bx bx-lock-open-alt'></i>
            </template>
          </vs-input>
          <!-- tags -->
          <vs-select
            filter
            multiple
            placeholder="select tags"
            v-model="selectedTags"
            style="margin-top: 10px;"
          >
            <vs-option-group v-for="(firstLevelTag, key) in data.tags" :key="key">
              <div slot="title">
                {{ firstLevelTag.name }}
              </div>
              <vs-option :label="secondLevelTag.name" :value="secondLevelTag.id" v-for="(secondLevelTag, k) in firstLevelTag.children" :key="k">
                {{ secondLevelTag.name }}
              </vs-option>
            </vs-option-group>
          </vs-select>
          <!-- status -->
          <vs-row style="margin-top: 20px;">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="2">
              <vs-switch v-model="data.status" success>
                <template #off>
                  Private
                </template>
                <template #on>
                  Public
                </template>
              </vs-switch>
            </vs-col>
          </vs-row>
          <!-- 消息队列信息 -->
          <vs-row style="margin: 10px;" v-if="['post_add_publish', 'draft_post_publish', 'post_modify_publish'].includes(modifyType)">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
              <!-- rabbitmq queue -->
              <vs-select
                placeholder="queue"
                v-model="queue"
                style="margin-top: 20px;"
              >
                <vs-option label="queue1" value="queue1">
                  queue1
                </vs-option>
                <vs-option label="queue2" value="queue2">
                  queue2
                </vs-option>
                <vs-option label="queue3" value="queue3">
                  queue3
                </vs-option>
                <vs-option label="queue4" value="queue4">
                  queue4
                </vs-option>
              </vs-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
              <!-- msg -->
              <vs-input color="#7d33ff" type="text" v-model="msg" placeholder="msg" class="msg-input-align">
                <template #icon>
                  <i class='bx bx-lock-open-alt'></i>
                </template>
              </vs-input>
            </vs-col>
          </vs-row>
        </div>
        <template #footer>
          <div style="display: flex; justify-content: flex-end;">
            <vs-button success @click="submit">
              Ok
            </vs-button>
            <vs-button danger @click="confirmDialogFlag = !confirmDialogFlag">
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>
    </div>
  </template>

<script>

import MdEditor from '@/components/MdEditor'
export default {
  components: {
    MdEditor
  },
  data () {
    return {
      rawContent: '',
      content: '',
      html: '',

      data: {
        id: '',
        title: '',
        description: '',
        content: '',
        record_name: '',
        record_time: null,
        status: true,
        tags: [
          {
            id: 1,
            name: '前端',
            children: [
              {
                id: 2,
                name: 'vue'
              },
              {
                id: 3,
                name: 'html'
              }
            ]
          },
          {
            id: 4,
            name: '后端',
            children: [
              {
                id: 5,
                name: 'java'
              }
            ]
          }
        ]
      },
      selectedTags: [3, 5],
      // 发布消息队列
      queue: '',
      // 发布消息的内容
      msg: '',

      confirmDialogFlag: false,

      draftDialogFlag: false,

      search: '',
      allCheck: false,
      page: 1,
      max: 10,
      selectedDraftList: [],
      draftList: [],

      // 当前页面处于的编辑状态 add:新增文章 draft:编辑草稿 post:编辑博客
      editType: this.$route.path.split('/')[2] === null ? 'add' : this.$route.path.split('/')[2],
      // save和publish按钮的行为
      modifyType: '',
      // id是否可编辑 true不可编辑 false可编辑
      disabled: true,

      this_: this
    }
  },
  methods: {
    getNewHtml (html) {
      this.html = html
    },
    submit () {
      const content_ = this.$refs.mdEditor.content_
      const modifyFlag = this.rawContent !== content_

      this.data.content = this.html

      if (modifyFlag) {
        // 新增到草稿表
        if (this.modifyType === 'draft_add') {
          this.request
            .POST('/draft/add', {
              params: this.data
            })
            .then((res) => {
              if (res.code === 0) {
                this.common.showNotification('success', 'request success', '新增博客成功', () => {
                  this.$router.push({ path: '/edit/post/' + this.data.id })
                })
              }
            })
        } else if (this.modifyType === 'draft_modify') { // 内容被编辑了就更新草稿
          this.request
            .POST('/draft/modify', {
              params: this.data
            })
            .then((res) => {
              if (res.code === 0) {
                this.confirmDialogFlag = false
                this.common.showNotification('success', 'request success', '更新草稿成功', () => {
                  // location.reload()
                  this.getData()
                })
              }
            })
        } else if (this.modifyType === 'post_modify') { // 内容被编辑了就更新博客
          this.request
            .POST('/post/modify', {
              params: this.data
            })
            .then((res) => {
              if (res.code === 0) {
                this.confirmDialogFlag = false
                this.common.showNotification('success', 'request success', '更新博客成功', () => {
                  // location.reload()
                  this.getData()
                })
              }
            })
        } else if (this.modifyType === 'post_add_publish') {
          this.request
            .POST('/post/add', {
              params: this.data
            })
            .then((res) => {
              this.publishRabbitMq()
            })
        } else if (this.modifyType === 'draft_post_publish') {
          this.request
            .POST('/draft/toPost', {
              params: this.data
            })
            .then((res) => {
              this.publishRabbitMq()
            })
        } else if (this.modifyType === 'post_modify_publish') {
          this.request
            .POST('/post/modify', {
              params: this.data
            })
            .then((res) => {
              this.publishRabbitMq()
            })
        }
      }
    },
    // 发布消息到消息队列
    publishRabbitMq () {
      this.request
        .POST('/rabbitmq/publish', {
          queue: this.queue,
          msg: this.msg
        }, 'localhost:60000')
        .then((res) => {
        })
    },
    publish () {
      if (this.editType === 'add') {
        this.modifyType = 'post_add_publish'
        this.disabled = false
      } else if (this.editType === 'draft') {
        this.modifyType = 'draft_post_publish'
      } else if (this.editType === 'post') {
        this.modifyType = 'post_modify_publish'
      }

      this.confirmDialogFlag = true
    },
    save () {
      if (this.editType === 'add') {
        this.modifyType = 'draft_add'
        this.disabled = false
      } else if (this.editType === 'draft') {
        this.modifyType = 'draft_modify'
      } else if (this.editType === 'post') {
        this.modifyType = 'post_modify'
      }

      this.confirmDialogFlag = true
    },
    deleteselectedDraftList (draftId) {
      if (draftId) {
      } else {
      }
    },
    showDraftDialog () {
      this.request
        .GET('/draft/getDrafts', {})
        .then((res) => {
          this.draftList = res.data

          this.draftDialogFlag = true

          this.search = ''
          this.allCheck = false
          this.selectedDraftList = []
        })
    },
    getData () {
      if (this.editType) {
        if (this.editType === 'post') {
          const postId = this.$route.params.postId

          this.request
            .GET('/post/get', {
              postId: postId
            })
            .then((res) => {
              this.rawContent = res.data.content
              this.content = res.data.content
              this.data = res.data
            })
        } else if (this.editType === 'draft') {
          const draftId = this.$route.params.draftId

          this.request
            .GET('/draft/get', {
              draftId: draftId
            })
            .then((res) => {
              this.rawContent = res.data.content
              this.content = res.data.content
              this.data = res.data
            })
        }
      }
    }
  },
  created () {
    this.getData()

    document.onkeydown = e => {
      if (e.ctrlKey && e.altKey && (e.key === 'i' || e.key === 'I')) {
        alert('导入')

        // 阻止默认事件
        e.preventDefault()
      }
      if (e.ctrlKey && e.altKey && (e.key === 'e' || e.key === 'E')) {
        alert('导出')

        // 阻止默认事件
        e.preventDefault()
      }
      if (e.ctrlKey && e.altKey && (e.key === 's' || e.key === 'S')) {
        this.save()

        // 阻止默认事件
        e.preventDefault()
      }
      if (e.ctrlKey && e.altKey && (e.key === 'p' || e.key === 'P')) {
        this.publish()

        // 阻止默认事件
        e.preventDefault()
      }
    }
  }
}
</script>
<style>
.msg-input-align {
  margin: 0 !important;
  margin-top: 10px !important;
}
</style>
